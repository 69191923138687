import NextLink, { LinkProps } from 'next/link';
import React from 'react';

interface CustomLinkProps extends LinkProps {
  className?: string;
  children?: React.ReactNode;
}

export default function Link({ children, ...props }: CustomLinkProps) {
  return (
    <NextLink
      onClick={(e) => {
        // e.preventDefault();
        console.log(`Navigate: ${props.href}`);
      }}
      {...props}
    >
      {children}
    </NextLink>
  );
}
