import React, { HTMLAttributes, ReactNode } from 'react';
import { Element } from './Text.styles';
import { DefaultTheme } from 'styled-components';

export interface TextProps extends HTMLAttributes<HTMLParagraphElement | HTMLDivElement | HTMLSpanElement> {
  readonly as?: 'div' | 'p' | 'small' | 'span' | 'strong' | 'dt' | 'dd' | 'li' | 'cite';
  readonly size?: 'xs' | 'sm' | 'base' | 'md' | 'lg' | 'xl';
  readonly weight?: 'light' | 'regular' | 'bold';
  readonly textCase?: 'uppercase' | 'lowercase' | 'capitalize' | 'none';
  readonly color?: keyof DefaultTheme['color'] & string;
  readonly margin?: keyof DefaultTheme['spacing'] & string;
  readonly align?: 'center';
  className?: string;
  children?: ReactNode;
}

const Text = ({ size, weight, children, ...props }: TextProps) => {
  return (
    <Element size={size} weight={weight} {...props}>
      {children}
    </Element>
  );
};

export default Text;
