import styled, { css } from 'styled-components';
import { ButtonProps } from './Button';

export const ButtonElement = styled.button.withConfig({
  shouldForwardProp: (props) => !['appearance', 'block', 'arrow', 'size'].includes(props),
})<ButtonProps>`
  ${({ theme, appearance, arrow, block, href, size }) => {
    return css`
      // Base style
      &,
      &[type='button'],
      &[type='reset'],
      &[type='submit'] {
        -webkit-appearance: button;

        &:not(:disabled) {
          cursor: pointer;
        }
      }

      vertical-align: middle;
      user-select: none;
      margin-block-start: 0;
      margin-block-end: 0;
      padding-block-start: 0;
      padding-block-end: 0;

      font-size: ${theme.typography.font_size.text_base};
      font-weight: ${theme.typography.font_weight.text_bold};
      padding: ${theme.spacing.sm} ${theme.spacing.base};
      text-align: center;

      // Style
      color: ${theme.color.white};
      border-color: ${theme.color.neutral_400};
      border-radius: ${theme.radius.xs};
      outline: 0;

      // Transition
      transition: ${theme.transition.all};

      // Sizing
      // Modifiers
      ${(block &&
        css`
          display: block;
          width: 100%;
        `) ||
      css`
        display: inline-block;
      `};

      // Appearance (default)
      ${appearance === 'default' &&
      css`
        background: ${theme.color.secondary_400};

        // States (default)
        &:hover {
          background: ${theme.color.secondary_600};
          color: ${theme.color.white};
        }

        &:focus {
          background: ${theme.color.secondary_600};
          border-color: ${theme.color.secondary_600};
          box-shadow: ${theme.box_shadow.sm};
        }
        &:active {
          background: ${theme.color.secondary_800};
        }
      `}

      // Appearance (primary)
      ${appearance === 'primary' &&
      css`
        background: ${theme.color.secondary_500};

        // States (default)
        &:hover {
          background: ${theme.color.secondary_800};
          color: ${theme.color.white};
        }
        &:focus {
          background: ${theme.color.secondary_600};
          border-color: ${theme.color.secondary_600};
          box-shadow: ${theme.box_shadow.sm};
        }
        &:active {
          background: ${theme.color.secondary_800};
        }
        &:disabled {
          background: ${theme.color.secondary_500};
        }
      `};

      // Appearance (outline)
      ${appearance === 'outline' &&
      css`
        background: transparent;
        border: 2px solid ${theme.color.white};

        // States (outline)
        &:hover,
        &:focus,
        &:active {
          color: ${theme.color.dark};
        }
        &:hover {
          background: ${theme.color.white};
        }

        &:focus {
          background: ${theme.color.neutral_200};
          border-color: ${theme.color.neutral_200};
          box-shadow: ${theme.box_shadow.sm};
        }
        &:active {
          background: ${theme.color.neutral_200};
        }
      `}

      // Appearance (link)
      ${appearance === 'link' &&
      css`
        color: ${theme.color.secondary_500};

        // States (link)
        &:hover,
        &:focus,
        &:active {
          color: ${theme.color.secondary_800};
        }
      `};

      // Arrow Icon
      span {
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: center;
        svg {
          transition: height 0.35s ease, width 0.35s ease-in-out;
          max-height: 1.5rem; // fix problem on 14px font-base
        }
      }

      ${arrow &&
      css`
        &:hover:not(:disabled) {
          svg {
            width: 33px;
            height: 18px;
          }
        }
      `}

      // Sizes
      ${size === 'large' &&
      css`
        font-size: ${theme.typography.font_size.text_md};
        border: 1px solid transparent; // 48px otherwise = 46px height
      `}

      &:disabled {
        opacity: 0.5;
      }
    `;
  }};
`;
