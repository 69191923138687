import React, { ReactNode } from 'react';
import { Text, Title } from '@/components/typography';
import { Element } from '@/components/typography/PageHeader/PageHeader.styles';
import { TitleProps } from '@/components/typography/Title/Title';

export interface PageHeaderProps extends TitleProps {
  className?: string;
  children?: ReactNode;
  title?: string;
  description?: string;
}

const PageHeader = ({ title, description, children, ...props }: PageHeaderProps) => {
  return (
    <Element>
      <Title weight="light" size="2xl" color="secondary_500" {...props}>
        {(title && title) || children}
      </Title>
      {description && <Text size="md">{description}</Text>}
    </Element>
  );
};

export default PageHeader;
