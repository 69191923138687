import styled, { css } from 'styled-components';
import { TextProps } from '@/components/typography/Text/Text';

export const Element = styled.p.withConfig({
  shouldForwardProp: (prop) => !['color', 'textCase', 'weight', 'margin', 'align'].includes(prop),
})<TextProps>`
  ${({ theme, color, textCase, weight, size, margin, align }) => {
    return css`
      // SIZE
      font-size: ${size
        ? css`
            ${theme.typography.font_size[`text_${size}`]};
          `
        : css`
            ${theme.typography.font_size.text_base};
          `};

      // WEIGHT
      font-weight: ${weight
        ? css`
            ${theme.typography.font_weight[`text_${weight}`]};
          `
        : css`
            ${theme.typography.font_weight.text_regular};
          `};

      // MARGIN
      ${margin &&
      css`
        margin-bottom: ${theme.spacing[margin]};
      `}

      // TEXT CASE
      ${textCase &&
      css`
        text-transform: ${textCase};
      `};

      // COLOR
      ${color &&
      css`
        color: ${theme.color[color]};
      `};

      // Alignment
      ${align &&
      css`
        text-align: ${align};
      `};
    `;
  }};
`;
