import styled, { css } from 'styled-components';

export const Element = styled.header`
  ${({ theme, ...props }) => {
    return css`
      border-bottom: 1px solid ${theme.color.neutral_200};
      margin-bottom: ${theme.spacing.base};

      > p {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    `;
  }};
`;
