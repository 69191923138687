import React from 'react';
import { HeaderElement } from '@/components/typography/TitleFeatured/TitleFeatured.styles';
import { Title } from '@/components/typography';
import { TitleProps } from '@/components/typography/Title/Title';
import Text from '@/components/typography/Text/Text';

export interface TitleFeaturedProps extends TitleProps {
  title: string;
  description?: string | undefined | null;
}

const TitleFeatured = ({ title, description, ...props }: TitleFeaturedProps) => {
  return (
    <HeaderElement>
      <Title size="xl" weight="light" {...props}>
        {title}
      </Title>
      {description && <Text as="div" size="base" align="center" dangerouslySetInnerHTML={{ __html: description }} />}
    </HeaderElement>
  );
};

export default TitleFeatured;
