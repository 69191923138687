import styled, { css } from 'styled-components';
import { TitleProps } from '@/components/typography/Title/Title';

export const Element = styled.h1.withConfig({
  shouldForwardProp: (props) => !['size', 'textCase', 'weight', 'color', 'margin', 'align'].includes(props),
})<TitleProps>`
  ${({ theme, size, weight, textCase, color, margin, align }) => {
    return css`
      line-height: normal;
      font-size: ${
        size
          ? css`
              ${theme.typography.font_size[`title_${size}`]}
            `
          : css`
              ${theme.typography.font_size.title_base}
            `
      };
      font-weight: ${
        weight
          ? css`
              ${theme.typography.font_weight[`title_${weight}`]}
            `
          : css`
              ${theme.typography.font_weight.title_semi_bold}
            `
      }};
      ${
        textCase &&
        css`
          text-transform: ${theme.typography.text_case[textCase]};
          > span {
            display: block;
            text-transform: initial;
            font-weight: lighter;
            font-size: ${theme.typography.font_size.title_base};
            color: ${theme.color.neutral_500};
          }
        `
      }};
      ${
        color &&
        css`
          color: ${theme.color[color]};
        `
      };
      ${
        margin &&
        css`
          margin-bottom: ${theme.spacing[margin]};
        `
      };
      // Alignment
      ${
        align &&
        css`
          text-align: ${align};
        `
      };
    `;
  }};
`;
