import styled, { css } from 'styled-components';
import { Element as TitleElement } from '@/components/typography/Title/Title.styles';

export const HeaderElement = styled.header`
  ${({ theme, ...props }) => {
    return css`
      // Title Component override
      ${TitleElement} {
        // Must have a border to make the round in the middle of the bar
        border: 1px dotted transparent;
        color: ${theme.color.secondary_500};
        text-align: center;

        position: relative;
        &:before,
        &:after {
          content: '';
        }
        &:before {
          border: 2px solid ${theme.color.primary_500};
          background: ${theme.color.primary_100};
          display: table;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;

          position: absolute;
          top: auto;
          bottom: 0.56rem;
          left: calc(50% - 0.5rem);
        }
        &:after {
          display: block;
          border: 1px solid ${theme.color.primary_500};
          max-width: 28.125rem; // 450px
          margin: ${theme.spacing.base} auto;
        }

        // Description
        + p,
        + div {
          //margin-top: 1rem;
        }
      }

      // Desktop
      @media screen and (min-width: ${theme.breakpoint.sm}) {
        // Title Component override
        ${TitleElement} {
          font-size: ${theme.typography.font_size.title_2xl};
        }
      }
    `;
  }};
`;
