import React, { MouseEvent, ReactNode } from 'react';
import { ButtonElement } from './Button.styles';
import Link from '@/providers/Link';
import { LinkProps } from 'next/link';

export interface ButtonProps {
  /** Tells this button should not use NextLink component*/
  external?: boolean;
  appearance?: 'default' | 'primary' | 'outline' | 'link';
  block?: boolean;
  className?: string;
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void | (() => void) | undefined | unknown;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  target?: React.AnchorHTMLAttributes<HTMLAnchorElement>['target'];
  tabIndex?: React.AnchorHTMLAttributes<HTMLAnchorElement>['tabIndex'];
  href?: string;
  arrow?: boolean;
  size?: 'base' | 'large';
  disabled?: boolean;
  replace?: LinkProps['replace'];
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ appearance = 'default', href, arrow, children, external, ...props }: ButtonProps, ref) => {
    const component = !href ? 'button' : external ? 'a' : Link;
    return (
      <ButtonElement href={href} as={component} ref={ref} appearance={appearance} arrow={arrow} {...props}>
        <span>
          {children}
          {arrow && (
            <svg width="17" height="9" viewBox="0 0 17 9" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.3536 4.85355C16.5488 4.65829 16.5488 4.34171 16.3536 4.14645L13.1716 0.964466C12.9763 0.769204 12.6597 0.769204 12.4645 0.964466C12.2692 1.15973 12.2692 1.47631 12.4645 1.67157L15.2929 4.5L12.4645 7.32843C12.2692 7.52369 12.2692 7.84027 12.4645 8.03553C12.6597 8.2308 12.9763 8.2308 13.1716 8.03553L16.3536 4.85355ZM0 5L16 5V4L0 4L0 5Z"
                fill="currentColor"
              />
            </svg>
          )}
        </span>
      </ButtonElement>
    );
  }
);

Button.displayName = 'Button';

export default Button;
