import React, { ReactNode } from 'react';
import { Element } from './Title.styles';
import { DefaultTheme } from 'styled-components';

export interface TitleProps {
  id?: React.AllHTMLAttributes<HTMLDivElement>['id'];
  readonly as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  readonly size?: 'base' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl';
  readonly weight?: 'light' | 'semi_bold' | 'black';
  readonly textCase?: keyof DefaultTheme['typography']['text_case'];
  readonly color?: keyof DefaultTheme['color'] & string;
  readonly margin?: keyof DefaultTheme['spacing'] & string;
  readonly align?: 'center' | 'left';
  className?: string;
  children?: ReactNode;
}

const Title = ({ size, weight, textCase, children, ...props }: TitleProps) => {
  return (
    <Element size={size} weight={weight} textCase={textCase} {...props}>
      {children}
    </Element>
  );
};

export default Title;
